import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// contexts
import { AuthProvider } from 'contexts/auth';
import { BackendProvider } from 'contexts/backend';

// styles
import 'antd/dist/antd.css';
import './index.scss';

import Shell from 'shell';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <BackendProvider>
                <AuthProvider>
                    <Shell />
                </AuthProvider>
            </BackendProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

