import config from 'config/config.json';
import verifyToken from './verify-token';

class Backend {
    constructor() {
        this.accessToken = null;
        this.accessTokenWorker = null;
    }

    getItem(key) {
        const json = localStorage.getItem(key);
        if (json == null) {
            return null;
        }

        try {
            return JSON.parse(json);
        }
        catch (error) {
            /* do nothing */
        }

        return null;
    }

    setItem(key, value) {
        if (value == null) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    }

    async getAccessToken() {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            return null;
        }

        if (!verifyToken(refreshToken)) {
            window.dispatchEvent(new Event('signout'));
        }

        const accessToken = this.accessToken;
        if (verifyToken(accessToken)) {
            return accessToken;
        }

        const user = this.getItem('user');
        if (!user) {
            return null;
        }

        if (!this.accessTokenWorker) {
            this.accessTokenWorker = this.doGetAccessToken({
                refreshToken,
                user
            });
        }

        return this.accessTokenWorker;
    }

    async doGetAccessToken(params) {
        const response = await fetch(this.getURL('/auth/token/refresh'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        });

        const data = await response.json();
        this.setAccessToken(data.accessToken);
        this.accessTokenWorker = null;

        return data.accessToken;
    }

    getRefreshToken() {
        const cookieValue = document.cookie
            ?.split('; ')
            ?.find(row => row.startsWith('refreshToken='))
            ?.split('=')
            ?.[1];
        return cookieValue;
    }

    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }

    setRefreshToken(refreshToken) {
        const year = new Date().getFullYear();
        document.cookie = `refreshToken=${refreshToken}; expires= 31 Dec ${year} 23:59:59 GMT; SameSite=None; Secure`;
    }

    getURL(url, params) {
        let result = `${config.api.host}${url}`;

        if (params) {
            result += `?${new URLSearchParams(params)}`;
        }

        return result;
    }

    async getHeaders(authorized) {
        let headers = {
            'Content-Type': 'application/json'
        };

        if (authorized) {
            const token = await this.getAccessToken();
            if (token) {
                headers = {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                };
            }
        }

        return headers;
    }

    async signIn(params) {
        const data = await this.post('/auth/sign-in', params, 'json', false);

        this.setAccessToken(data.accessToken);
        this.setRefreshToken(data.refreshToken);

        return data;
    }

    async signOut() {
        this.setAccessToken(null);
        this.setRefreshToken(null);
    }

    async get(url, params, contentType = 'json', authorized = true) {
        const response = await fetch(this.getURL(url, params), {
            method: 'GET',
            headers: await this.getHeaders(authorized)
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }

    async post(url, params, contentType = 'json', authorized = true) {
        const response = await fetch(this.getURL(url), {
            method: 'POST',
            headers: await this.getHeaders(authorized),
            body: params ? JSON.stringify(params) : null
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }

    async delete(url, contentType = 'json', authorized = true) {
        const response = await fetch(this.getURL(url), {
            method: 'DELETE',
            headers: await this.getHeaders(authorized)
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }
}

export default Backend;
