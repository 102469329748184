import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

// hooks
import useLocalStorage from 'hooks/use-local-storage';

import { useBackend } from 'contexts/backend';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
    // states
    const backend = useBackend();
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useLocalStorage('user', null);
    const [currentOrganization, setCurrentOrganization] = useLocalStorage('organization', null);
    const [currentUserPhoto, setCurrentUserPhoto] = useLocalStorage('photo', null);
    const [role, setRole] = useLocalStorage('role', null);

    // callbacks
    const signOut = useCallback(async() => {
        setCurrentUser(null);
        setCurrentOrganization(null);
        setCurrentUserPhoto(null);
        setRole(null);
        await backend.signOut();
        navigate('/sign-in');
    }, [navigate, setCurrentOrganization, setCurrentUser, setCurrentUserPhoto, setRole, backend]);

    useEffect(() => {
        const listener = window.addEventListener('signout', signOut);

        return () => {
            window.removeEventListener('signout', listener);
        };
    }, [signOut]);

    // memos
    const value = useMemo(() => ({ currentUser, setCurrentUser, currentOrganization, setCurrentOrganization, role, setRole, currentUserPhoto, setCurrentUserPhoto, signOut }), [currentUser, setCurrentUser, currentOrganization, setCurrentOrganization, role, setRole, currentUserPhoto, setCurrentUserPhoto, signOut]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    return useContext(AuthContext);
}

export {
    AuthProvider,
    useAuth
};
