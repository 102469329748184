import React, { Suspense, lazy } from 'react';

// contexts
import { useAuth } from 'contexts/auth';

// modules
const App = lazy(() => import('modules/app'));
const Web = lazy(() => import('modules/web'));

export default function Shell() {
    const { currentUser } = useAuth();

    return (
        <Suspense fallback={<div />}>
            {currentUser ? <App /> : <Web />}
        </Suspense>
    );
}
