import { useEffect, useState } from 'react';

export default function useLocalStorage(key, initialValue) {
    const [value, setValue] = useState(initialValue);

    const saveValue = value => {
        setValue(value);

        if (value == null) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    };

    useEffect(() => {
        const listener = window.addEventListener('storage', e => {
            if (e.key === key) {
                setValue(e.newValue);
            }
        });

        return () => {
            window.removeEventListener('storage', listener);
        };
    }, [key]);

    useEffect(() => {
        const json = localStorage.getItem(key);
        if (json == null) {
            setValue(null);
            return;
        }

        try {
            setValue(JSON.parse(json));
        }
        catch (error) {
            setValue(null);
        }
    }, [key]);

    return [value, saveValue];
}
