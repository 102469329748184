import { Buffer } from 'buffer';

export default function verifyToken(token) {
    if (!token || token === 'null' || token === 'undefined') {
        return false;
    }
    try {
        const buffer = new Buffer(token.split('.')[1], 'base64');
        const { exp } = JSON.parse(buffer.toString('ascii'));
        if (Date.now() > exp * 1000) {
            return false;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }

    return true;
}
