import React, { useContext, useRef } from 'react';

// helpers
import Backend from 'helpers/backend';

const BackendContext = React.createContext();

function BackendProvider({ children }) {
    const backend = useRef(new Backend());

    return (
        <BackendContext.Provider value={backend.current}>
            {children}
        </BackendContext.Provider>
    );
}

function useBackend() {
    return useContext(BackendContext);
}

export {
    BackendProvider,
    useBackend
};
